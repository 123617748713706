<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <div class="integral-flow-header">
        <div>
          <div>会员账号</div>
          <div class="value">{{ userInfo.memberAccount }}</div>
        </div>
        <div>
          <div>当前积分</div>
          <div class="value">{{ userInfo.points }}</div>
        </div>
      </div>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table ref="YkcTable" title="积分流水" :data="tableData" :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" @click="exportData">导出</ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { marketManage } from '@/service/apis';
  import { localGetItem, offlineExport } from '@/utils';

  export default {
    name: 'IntegralFlow',
    data() {
      return {
        userInfo: {},
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '积分流水号', prop: 'flowNo' },
          { label: '流水时间', prop: 'flowTime' },
          { label: '积分调整', prop: 'flowValueDesc' },
          { label: '变动原因', prop: 'flowTypeDesc' },
          { label: '备注', prop: 'remark' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          flowTime: '',
          flowType: '',
          flowNo: '',
        },
      };
    },
    computed: {
      searchData() {
        const { member_points_flow_type = [] } = localGetItem('dictionary');
        return [
          {
            comName: 'YkcDatePicker',
            key: 'flowTime',
            label: '流水时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'flowType',
            label: '变动原因',
            placeholder: '请选择变动原因',
            data: member_points_flow_type.map(({ code, name }) => ({ id: code, name })),
          },
          {
            comName: 'YkcInput',
            key: 'flowNo',
            label: '流水号',
            placeholder: '请输入积分流水号',
          },
        ];
      },
    },
    created() {
      this.getDetail();
      this.searchTableList();
    },
    methods: {
      exportData() {
        const { userAccount } = this.$route.query;
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              userAccount,
              downloadKey: 'member_points_flow_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      getDetail() {
        const { id } = this.$route.query;
        marketManage.memberDetails({ userId: id }).then(response => {
          console.log('getDetail response', response);
          this.userInfo = response;
        });
      },
      searchTableList() {
        const { userAccount } = this.$route.query;
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
          userAccount,
        };
        console.log('data', data);
        if (Array.isArray(data.flowTime) && data.flowTime.length === 2) {
          [data.flowStartTime, data.flowEndTime] = data.flowTime;
          delete data.flowTime;
        }
        marketManage.queryPointsFlowForPage(data).then(res => {
          console.log('queryPointsFlowForPage res', res);
          const { records, current, total } = res;
          this.tableData = records;
          this.pageInfo.pageIndex = current;
          this.pageInfo.total = total;
        });
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .integral-flow-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 16px 64px;
    border: 1px solid #eee;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .value {
        font-weight: 600;
        font-size: 18px;
        margin-top: 8px;
      }
    }
  }
</style>
